import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Field, FieldArray, ErrorMessage } from 'formik';
import BSFormControl from '../common/form/bsFormControl';
import SliderSelector from "../common/form/sliderSelector";

import * as Icons from 'react-feather';
import { getSliderMarks } from "../../helpers/topicBuilderHelper";
import formValidators from '../../helpers/formValidators';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import "./css/greetings.css";
import MediaDnD from '../common/form/MediaDnD';
import { processQuestionImage } from '../../helpers/imageUtils';
import { useTranslation } from 'react-i18next';

const GreetingMessages = (props, context) => {
    const { values, errors, setFieldValue, setFieldError, setValues } = props;
    const { greetings, translations } = values;

    const hasGreetings = greetings.messages && greetings.messages.length > 0;
    const marks = getSliderMarks();

    const { t } = useTranslation();

    function handleAddGreetingClick() {
        const newGreeting = {
            id: getNewMessageId(),
            text: ''
        };

        const updatedTranslations = Object.assign({}, translations);
        addNewGreetingToTranslations(updatedTranslations, newGreeting);

        const updatedGreetings = Object.assign({}, greetings, {
            messages: [...greetings.messages, newGreeting]
        });

        const newState = Object.assign({}, values, {
            greetings: updatedGreetings,
            translations: updatedTranslations
        });
        setValues(newState);
    }

    function addNewGreetingToTranslations(translations, greeting) {
        const langs = Object.keys(translations);
        langs.forEach(l => {
            if (translations[l].greetings) {
                translations[l] = Object.assign({}, translations[l], {
                    greetings: Object.assign({}, translations[l].greetings, {
                        [greeting.id]: {
                            text: ""
                        }
                    })
                });
            } else {
                translations[l] = Object.assign({}, translations[l], {
                    greetings: {
                        [greeting.id]: { text: "" }
                    }
                });
            }
        });
    }

    function getNewMessageId() {
        const messages = [...greetings.messages];
        const sortedMessagesByIdDesc = messages.sort((a, b) => Number(b.id) - Number(a.id));

        let newId = sortedMessagesByIdDesc.length > 0 ? sortedMessagesByIdDesc[0].id + 1 : 1;

        return newId;
    }

    function handleDeleteClick(id) {
        const greetToDelete = greetings.messages.find(m => m.id === id);

        if (isEmpty(greetToDelete.text)) {
            onConfirmDeleteItem(id);
        } else {
            handleOpenConfirmDelete(greetToDelete);
        }
    }

    function handleOpenConfirmDelete(greeting) {
        const modalConfig = {
            title: t('modal_greetings_delete_title'),
            message: t('modal_greetings_delete_message', { greetingText: greeting.text }),
            onClose: () => context.hideModal(),
            onConfirm: () => onConfirmDeleteItem(greeting.id),
        };

        context.showModal(CONFIRM_MODAL, modalConfig);
    }

    function onConfirmDeleteItem(id) {
        context.hideModal();

        const greetToDelete = greetings.messages.find(m => m.id === id);

        const updatedGreetingMessages = greetings.messages.filter((q) => q.id !== id);
        const updatedTranslations = removeGreetingFromTranslations(greetToDelete.id);

        const newFormValuesState = Object.assign({}, values, {
            greetings: Object.assign({}, greetings, { messages: updatedGreetingMessages }),
            translations: updatedTranslations
        });

        setValues(newFormValuesState);
    }

    function removeGreetingFromTranslations(greetId) {
        const updatedTranslations = Object.assign({}, translations);
        const langs = Object.keys(translations);

        langs.forEach(l => {
            if (translations[l].greetings) {
                const gIds = Object.keys(translations[l].greetings);
                const updatedGreetingsTranslations = {};
                gIds.forEach(id => {
                    if (Number(id) !== Number(greetId)) {
                        updatedGreetingsTranslations[id] = translations[l].greetings[id];
                    }
                });

                updatedTranslations[l] = Object.assign({}, updatedTranslations[l], { greetings: updatedGreetingsTranslations });
            }
        });

        return updatedTranslations;
    }

    function renderGreetings() {
        if (hasGreetings) {
            return greetings.messages.map((message, index) => (
                <div key={index} className="form-group ordered-form-fields uitest-greetings">
                    <div className="ordered-form-fields-item with-action">
                        <div className="number">{index + 1}</div>

                        <div className="form-control-container">
                            <BSFormControl required hasErrors={formValidators.fieldHasErrors(errors, `greetings.messages.${index}.text`)}>
                                <Field
                                    name={`greetings.messages.${index}.text`}
                                    value={message.text}
                                    type="text"
                                    className={`form-control uitest-greeting-item`}
                                />
                                <ErrorMessage name={`greetings.messages.${index}.text`} component="div" className='control-label' />
                            </BSFormControl>
                        </div>

                        <span className="action">
                            <Icons.XCircle className="text-danger" onClick={() => handleDeleteClick(message.id)} />
                        </span>
                    </div>
                </div>
            ));
        }
    }

    return (
        <div className="greetings-container row-fluid clearfix">
            <FieldArray
                name="greetings.messages"
                render={() => (
                    <div className="row-fluid clearfix">
                        {renderGreetings()}

                        <button
                            className="btn btn-dotted btn-outline btn-success btn-full-width"
                            onClick={handleAddGreetingClick}
                            type="button"
                        >
                            <Icons.PlusCircle size={16} /> {t('topic_builder_greetings_add_new')}
                        </button>

                    </div>
                )}
            />

            <div className="slider-container">
                <span className="duration-label text-uppercase">{t('topic_builder_greetings_time_to_show')}</span>
                <Field
                    name="greetings.duration"
                    value={greetings.duration}
                >
                    {({ field }) =>
                        <SliderSelector
                            value={field.value}
                            marks={marks}
                            min={1}
                            max={10}
                            onAfterChange={(selectedDuration) => setFieldValue(field.name, selectedDuration)}
                            dots
                        />
                    }
                </Field>
                <span className="duration-mini-label text-uppercase">{t('topic_builder_greetings_seconds')}</span>
            </div>
            <MediaDnD
                model={values}
                previewField={'greetings.mediaUrl'}
                legacyField={'greetings.videoUrl'}
                uploadImageEndpoint={`/topics/${values.basicSettings.id}/upload_images/greeting`}
                uploadVideoEndpoint={`/topics/${values.basicSettings.id}/upload_video/greeting`}
                imageProcessor={processQuestionImage}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                fieldNames={['greetings.mediaUrl']}
            />
        </div>
    );
};

GreetingMessages.contextTypes = {
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
};

GreetingMessages.propTypes = {
    values: PropTypes.shape({
        greetings: PropTypes.shape({
            messages: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string,
                id: PropTypes.number
            })),
            videoUrl: PropTypes.string,
            duration: PropTypes.number
        }),
    }).isRequired,

    backendErrors: PropTypes.shape({
        greetings: PropTypes.object
    }),
    errors: PropTypes.object,

    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired
};

export default GreetingMessages;