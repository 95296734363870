import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

import BSFormControl from '../common/form/bsFormControl';
import ServerErrorMessage from '../common/serverErrorMessage';
import { Toggle } from '../common/form/toggle';
import AutoComplete from '../common/form/autocomplete';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import NoPermissionLabel from '../../components/common/noPermissionLabel';

import utils from 'hollerlive-shared/utils';
import { LOCALE } from '../../helpers/locales';
import { TIMEZONES } from '../../helpers/topicBuilderHelper';
import { USER_ROLES, isCurrentRole, CONSENTIO_ENTITY_ID } from '../../helpers/user';
import { isEmpty, isEqual } from 'lodash';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import messages from '../../helpers/messages';
import { isOnMobile } from '../../helpers/utils';
import { processAvatarImage } from '../../helpers/imageUtils';
import MediaDnD from '../common/form/MediaDnD';
import './css/editUserForm.css';
import i18n from '../../i18n';

export class EditUserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActiveToggleDisabled: props.activeToggleDisabled,
            t: i18n.t.bind(i18n)
        };
    }

    componentWillUnmount() {
        this.props.resetRequestErrors();
    }

    componentDidMount() {
        if (!this.props.hideActiveToggle) {
            this.setActiveToggle();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.values.entityId !== this.props.values.entityId) {
            if (!this.props.hideActiveToggle) {
                this.setActiveToggle();
            }
        }
        if ((!isEmpty(this.props.requestErrors) && isEmpty(prevProps.requestErrors)) ||
            !isEqual(this.props.values, prevProps.values)) {
            this.props.resetSubmitting(false);
        }
        if (isEmpty(this.props.requestErrors) && !isEmpty(prevProps.requestErrors)) {
            this.props.resetRequestErrors();
        }
    }

    setActiveToggle() {
        const { setFieldValue, touched, setTouched } = this.props;
        const shouldDisable = this.shouldDisableActiveToggle;
        this.setState({ isActiveToggleDisabled: shouldDisable }, () => {
            if (shouldDisable) {
                setFieldValue('active', false);
            } else if (this.props.isCreateMode) {
                setFieldValue('active', true);
            }

            const touchedValues = Object.assign({}, touched, { 'active': true });
            setTouched(touchedValues);
        });
    }

    get shouldDisableActiveToggle() {
        const { values } = this.props;
        const selectedEntity = this.props.entities.find(entity => entity.id === values.entityId);
        if (!isEmpty(selectedEntity)) {
            return !selectedEntity.active;
        }

        return true;
    }

    get emailSublabelText() {
        const t = this.state.t;
        return `${t('edit_profile_field_email_sublabel_1')}${this.props.isCreateMode ? `${t('edit_profile_field_email_sublabel_2')}` : ''}`;
    }

    fieldHasErrors(errors, field) {
        if (this.props.requestErrors[field] && this.props.requestErrors[field].length > 0) {
            return true;
        }
        return errors[field] && errors[field].length > 0;
    }

    handleUserRole(value, roleField, setFieldValue) {
        let userRoleId;
        if (value) {
            switch (roleField) {
                case 'superAdmin':
                    userRoleId = USER_ROLES.superAdmin;
                    break;
                case 'entityManager':
                    userRoleId = USER_ROLES.entityManager;
                    break;
                case 'entityViewer':
                    userRoleId = USER_ROLES.entityViewer;
                    break;
                case 'topicEditor':
                    userRoleId = USER_ROLES.topicEditor;
                    break;
                default:
                    userRoleId = USER_ROLES.entityViewer;
            }
        }
        else {
            userRoleId = USER_ROLES.entityViewer;
        }

        setFieldValue('roleId', userRoleId);
    }

    _getTimeZoneOptions() {
        return TIMEZONES.map((tz, i) => {
            if (i === 0) {
                return <option key={0} value={''}>{''}</option>;
            }
            return <option key={i} value={tz.id}>{tz.name}</option>;
        });
    }

    _getLocaleOptions() {
        return LOCALE.map((lc, i) => {
            return <option key={i} value={lc.id}>{lc.name}</option>;
        });
    }

    _getLanguageOptions() {
        const t = this.state.t;
        return (
            <>
                <option disabled value={''}>{t('lang_none')}</option>;
                <option value={'en'}>{t('lang_en')}</option>;
                <option value={'bg'}>{t('lang_bg')}</option>
            </>
        );
    }

    get shouldDisableEntityChange() {
        return (this.props.values.roleId === USER_ROLES.superAdmin) || this.props.hideEntityChange;
    }

    /** Should disable fields for users with insufficient permissions or who belong to no entity */
    get shouldDisableFields() {
        const {
            values,
            hasSpecialPermissions
        } = this.props;

        if (this.props.values.entityId === null) {
            return true;
        }

        if (values.roleId === USER_ROLES.superAdmin) {
            return !hasSpecialPermissions;
        } else {
            return false;
        }
    }

    get industries() {
        return utils.getIndustries().map((industry, i) => {
            return {
                id: i,
                name: industry
            };
        });
    }

    render() {
        const {
            values,
            entities,
            setFieldValue,
            errors,
            requestErrors,
            setFieldError,
            includePassword,
            emailReadOnly,
            hideActiveToggle,
            hideEntityChange,
            hidePhone,
            hideTimeZone,
            hideRoles,
            hideEntityName,
            hideIndustry,
            hideLocale,
            canSetAsManager,
            includeAvatar
        } = this.props;

        const t = this.state.t;

        return (
            <div className="user-edit-form uitest-user-edit-form">
                {this.fieldHasErrors(requestErrors, 'version') && <ServerErrorMessage errors={this.props.requestErrors} name="version" className="control-label" />}
                {this.fieldHasErrors(requestErrors, 'active') && <ServerErrorMessage errors={this.props.requestErrors} name="active" className="control-label" />}

                {!hideActiveToggle &&
                    <div className="row-fluid clearfix">
                        <BSFormControl>
                            <Field name="active">
                                {({ field }) =>
                                    <Toggle
                                        value={field.value}
                                        onChange={(value) => setFieldValue(field.name, value)}
                                        disabled={this.shouldDisableFields || this.state.isActiveToggleDisabled}
                                    />
                                }
                            </Field>
                            <label className="control-label" htmlFor="entityManager">active</label>
                        </BSFormControl>
                    </div>
                }

                <div className={`row-fluid clearfix user-avatar-container ${isOnMobile() ? '' : 'fit-desktop-flex-container'}`}>
                    {!values.id ?
                        <p style={{ margin: '10px 0 30px 10px' }}>
                            След създаването на потребителя, можете да прикачите профилна снимка.
                        </p> :
                        <MediaDnD
                            label={t('edit_profile_field_avatar')}
                            model={values}
                            previewField={'avatarUrl'}
                            //mediaUrl={values.avatarUrl}
                            uploadImageEndpoint={`/users/${values.id}/upload_images/avatar`}
                            imageProcessor={processAvatarImage}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            fieldNames={['avatarUrl']}
                            showPreview={false}
                        />
                    }
                    <div className={`pull-left col-xs-12 ${includeAvatar ? 'col-md-8' : 'col-md-12'} user-main-data ${isOnMobile() ? '' : 'fit-desktop-flex-item'}`}>
                        <div className="row-fluid clearfix">
                            <div className="col-md-12 col-xs-12">
                                <div className="first-name">
                                    <BSFormControl label={t('edit_profile_field_first_name')} required hasErrors={this.fieldHasErrors(errors, 'firstName')} >
                                        <Field type="name" name="firstName" className="form-control" value={values.firstName} />
                                        <ErrorMessage name="firstName" component="span" className='control-label' />
                                    </BSFormControl>
                                </div>
                            </div>
                        </div>

                        <div className="row-fluid clearfix">
                            <div className="col-md-12 col-xs-12">
                                <div className="last-name">
                                    <BSFormControl label={t('edit_profile_field_last_name')} required hasErrors={this.fieldHasErrors(errors, 'lastName')} >
                                        <Field type="text" name="lastName" className="form-control" value={values.lastName} />
                                        <ErrorMessage name="lastName" component="span" className='control-label' />
                                    </BSFormControl>
                                </div>
                            </div>
                        </div>

                        <div className="row-fluid clearfix">
                            <div className="col-md-12 col-xs-12">
                                <div className="language">
                                    <BSFormControl label={t('edit_profile_field_user_language')}>
                                        <Field component="select" name="language" className="form-control" value={values.language} >
                                            {this._getLanguageOptions()}
                                        </Field>
                                    </BSFormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {includePassword &&
                    <div className="row-fluid clearfix">
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl className="uitest-password" label='password' required hasErrors={this.fieldHasErrors(errors, 'password')} >
                                <Field type="password" name="password" className="form-control" value={values.password} autoComplete='new-password' />
                                <ErrorMessage name="password" component="span" className='control-label' />
                            </BSFormControl>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl className="uitest-confirm-password" label='confirm password' required hasErrors={this.fieldHasErrors(errors, 'confirmPassword')} >
                                <Field type="password" name="confirmPassword" className="form-control" value={values.confirmPassword} autoComplete='new-password' />
                                <ErrorMessage name="confirmPassword" component="span" className='control-label' />
                            </BSFormControl>
                        </div>
                    </div>
                }

                <div className="row-fluid clearfix">
                    <div className='col-md-6 col-xs-12'>
                        <BSFormControl subLabel={this.emailSublabelText} className="uitest-email-field" label={t('edit_profile_field_email')} required={!emailReadOnly} hasErrors={this.fieldHasErrors(errors, 'email')} >
                            {emailReadOnly ?
                                <label>{values.email}</label>
                                :
                                <div>
                                    <Field type="text" name='email' value={values.email} className="form-control" autoComplete="off" />
                                    <ErrorMessage name="email" component="span" className='control-label' />
                                    {this.fieldHasErrors({}, 'email') && <ServerErrorMessage errors={this.props.requestErrors} name="email" className="control-label" />}
                                </div>
                            }
                        </BSFormControl>
                    </div>
                    {!hidePhone &&
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl label={t('edit_profile_field_phone')} hasErrors={this.fieldHasErrors(errors, 'phone')}>
                                <Field type="text" name="phone" className="form-control" value={values.phone} />
                                <ErrorMessage name="phone" component="span" className='control-label' />
                            </BSFormControl>
                        </div>
                    }
                </div>

                <div className="row-fluid clearfix">
                    {!hideTimeZone && <div className="col-md-6 col-xs-12">
                        <BSFormControl label={t('edit_profile_field_timezone')}>
                            <Field component="select" name="timeZone" className="form-control" value={values.timeZone}>
                                {this._getTimeZoneOptions()}
                            </Field>
                        </BSFormControl>
                    </div>
                    }
                    {!hideLocale && <div className="col-md-6 col-xs-12">
                        <BSFormControl label={t('edit_profile_field_user_locale')}>
                            <Field component="select" name="locale" className="form-control" value={values.locale} >
                                {this._getLocaleOptions()}
                            </Field>
                        </BSFormControl>
                    </div>
                    }
                </div>

                <div className="row-fluid clearfix">
                    {!hideEntityName &&
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl
                                className="uitest-entity-name"
                                label={t('edit_profile_field_organization')}
                                hasErrors={this.fieldHasErrors(errors, 'entityName')}
                                subLabel="Your company or organization name will be the name of your first topic"
                            >
                                <Field type="text" name="entityName" className="form-control" value={values.entityName} />
                                <ErrorMessage name="entityName" component="span" className='control-label' />
                                {this.fieldHasErrors({}, 'entityName') && <ServerErrorMessage errors={this.props.requestErrors} name="entityName" className="control-label" />}
                            </BSFormControl>
                        </div>
                    }
                    {!hideIndustry &&
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl className="uitest-indsutry" label='industry or sector' subLabel="select the industry or sector your organization operates in">
                                <Field name="industryId" value={values.industryId} >
                                    {({ field }) =>
                                        <AutoComplete
                                            value={field.value || ''}
                                            placeholder="Select an indsutry sector"
                                            className="form-control"
                                            data={this.industries || []}
                                            onChange={(value) => setFieldValue(field.name, value)}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="industryId" component="span" className='control-label' />
                            </BSFormControl>
                        </div>
                    }
                </div>

                <div className="row-fluid clearfix">
                    {!hideEntityChange &&
                        <div className="col-md-6 col-xs-12">
                            <BSFormControl className="uitest-entity-autoscomplete" label="selected organization" required hasErrors={this.fieldHasErrors(errors, 'entityId')}>
                                <Field name="entityId" value={values.entityId} >
                                    {({ field }) =>
                                        <AutoComplete
                                            value={field.value || ''}
                                            placeholder="Select an entity"
                                            className="form-control"
                                            data={entities || []}
                                            onChange={(value) => setFieldValue(field.name, value)}
                                            disabled={this.shouldDisableEntityChange}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="entityId" component="span" className='control-label' />
                                {this.fieldHasErrors({}, 'entityId') && <ServerErrorMessage errors={requestErrors} name="entityId" className="control-label" />}
                            </BSFormControl>
                        </div>
                    }
                    {!hideRoles &&
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group clearfix">
                                <BSFormControl className="uitest-roles" label='user role' required>
                                    <label className="sub-label">user must have an organization selected and exactly one role</label>
                                    <Field name="roleId">
                                        {() =>
                                            <div>
                                                <PermissionsContext requiredPermissions={[PERMISSIONS.ADMIN]} hideIfNoPermission={true} >
                                                    {values.entityId === CONSENTIO_ENTITY_ID ?
                                                        <div className="uitest-su-role-toggle">
                                                            <Toggle
                                                                value={!this.isEntityEmpty ? isCurrentRole(values.roleId, 'superAdmin') : false}
                                                                onChange={(value) => this.handleUserRole(value, 'superAdmin', setFieldValue)}
                                                                disabled={this.isEntityEmpty}
                                                            />
                                                            <label className="control-label" htmlFor="superAdmin">Admin</label>
                                                            <label className="sub-label">special super user permission</label>
                                                        </div> : null
                                                    }
                                                </PermissionsContext>

                                                {canSetAsManager ?
                                                    <>
                                                        <Toggle
                                                            value={!this.shouldDisableFields ? isCurrentRole(values.roleId, 'entityManager') : false}
                                                            onChange={(value) => this.handleUserRole(value, 'entityManager', setFieldValue)}
                                                            disabled={this.shouldDisableFields}
                                                        />
                                                        <label className="control-label" htmlFor="entityManager">organization manager</label>
                                                        <label className="sub-label">edit &amp; view organization details, create &amp; manage topics, view topics analytics</label>
                                                    </>
                                                    :
                                                    <>
                                                        <NoPermissionLabel mode="left" message={messages.permissions.features.insufficientNumberOfManagers} />
                                                        <Toggle value={!this.shouldDisableFields ? isCurrentRole(values.roleId, 'entityManager') : false} disabled={true} />
                                                        <label className="control-label">Organization Manager</label>
                                                        <label className="sub-label">edit &amp; view organization details, create &amp; manage topics, view topics analytics</label>
                                                    </>
                                                }

                                                <Toggle
                                                    value={!this.shouldDisableFields ? isCurrentRole(values.roleId, 'entityViewer') : false}
                                                    onChange={(value) => this.handleUserRole(value, 'entityViewer', setFieldValue)}
                                                    disabled={this.shouldDisableFields}
                                                />
                                                <label className="control-label" htmlFor="entityViewer">organization viewer</label>
                                                <label className="sub-label">view organization details, view topics analytics</label>

                                                <Toggle
                                                    value={!this.shouldDisableFields ? isCurrentRole(values.roleId, 'topicEditor') : false}
                                                    onChange={(value) => this.handleUserRole(value, 'topicEditor', setFieldValue)}
                                                    disabled={this.shouldDisableFields}
                                                />
                                                <label className="control-label" htmlFor="topicEditor">topic editor</label>
                                                <label className="sub-label">create &amp; manage topics, view topics analytics</label>
                                            </div>
                                        }
                                    </Field>
                                    <ErrorMessage name="roleId" component="span" className='control-label' />
                                </BSFormControl>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

EditUserForm.propTypes = {
    values: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
        phone: PropTypes.string,
        timeZone: PropTypes.string,
        locale: PropTypes.string,
        roleId: PropTypes.number,
        active: PropTypes.bool,
        entityId: PropTypes.number
    }),
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    touched: PropTypes.shape(),
    requestErrors: PropTypes.shape(),
    setFieldError: PropTypes.func,
    errors: PropTypes.shape(),
    /** @prop {func} resetRequestErrors a handler to reset errors in application state,
     * invoked on component unmount and when the new props does not contain errors anymore */
    resetRequestErrors: PropTypes.func.isRequired,
    /** @prop {func} resetSubmitting a handler to reset form submitting flag when request errors are */
    resetSubmitting: PropTypes.func,
    /** @prop {bool} includePassword if set to true will include password and confirm password
     * fields to the form. Defaults to false */
    includePassword: PropTypes.bool,
    /** @prop {bool} includeAvatar if set to false will not show avatar upload. Defaults to true */
    includeAvatar: PropTypes.bool,
    /** @prop {bool} emailReadOnly if set to true will disable editing the email field to the form.
     * Default to false */
    emailReadOnly: PropTypes.bool,
    /** @prop {bool} hasSpecialPermissions if set to true will enable special purpose controls, 
     * such as setting a super user role. Default to false  */
    hasSpecialPermissions: PropTypes.bool,
    /** @prop {bool} activeToggleDisabled initializes the active/inactive toggle disabled attribute.
     * Defaults to false */
    activeToggleDisabled: PropTypes.bool,
    /** @prop {bool} hideEntityChange disables changing the organization from the dropdown
     * Defaults to false */
    hideEntityChange: PropTypes.bool,
    hideActiveToggle: PropTypes.bool,
    hidePhone: PropTypes.bool,
    hideTimeZone: PropTypes.bool,
    hideRoles: PropTypes.bool,
    hideEntityName: PropTypes.bool,
    hideIndustry: PropTypes.bool,
    hideLocale: PropTypes.bool,
    isCreateMode: PropTypes.bool,
    /** 
     * @prop {bool} canSetAsManager if false, a "not available for your plan" will be displayed and 
     * "organization manager" role toggle will be disabled */
    canSetAsManager: PropTypes.bool
};

EditUserForm.defaultProps = {
    includePassword: false,
    includeAvatar: true,
    emailReadOnly: false,
    errors: {},
    touched: {},
    setTouched: () => { },
    resetSubmitting: () => { },
    requestErrors: {},
    hasSpecialPermissions: false,
    entities: [],
    hideActiveToggle: false,
    hidePhone: false,
    hideTimeZone: false,
    hideRoles: false,
    hideEntityName: true,
    hideIndustry: true
};

export default EditUserForm;
