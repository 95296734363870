import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Save } from 'react-feather';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';

import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import { mapToFormModel, validateUser } from '../../actions/models/userModel';
import EditUserForm from '../users/editUserForm';
import { CONFIRM_MODAL } from '../../helpers/modalTypes';
import messages from '../../helpers/messages';
import { USER_ROLES } from '../../helpers/user';
import { useTranslation } from 'react-i18next';

const UserDetailsEdit = ({
    userDetails,
    entities,
    usersList,
    hasSpecialPermissions,
    onSubmit,
    onCancel,
    showModal,
    hideModal,
    requestErrors,
    resetUserRequestErrors,
}) => {
    // form states
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);
    // entity-dependent user states
    const [canSetAsManager, setCanSetAsManager] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = (value) => {
        onSubmit(value);
    };

    const handleCancel = () => {
        if (!isDirty) {
            onCancel();
        } else {
            const configCancelModal = {
                title: messages.common.defaultConfirmTitle,
                message: messages.common.confirmUnsavedChangesMessage,
                onClose: () => {
                    hideModal();
                },
                onConfirm: () => {
                    onCancel();
                    hideModal();
                },
            };

            showModal(CONFIRM_MODAL, configCancelModal);
        }
    };

    const validate = (values) => {
        const validationOptions = { shouldValidateEmail: false, shouldValidatePassword: false };
        return validateUser(values, validationOptions);
    };

    const isSubmitDisabled = () => {
        return isDirty ? !isValid : true;
    };

    const setEntityRestrictionsById = (id) => {
        let userEntity;
        if (id) {
            userEntity = entities.find(ent => Number(ent.id) === Number(id));
        }

        if (!isEmpty(userEntity)) {
            const managers = usersList.filter(user => Number(user.roleId) === USER_ROLES.entityManager);
            const numManagersLimit = userEntity.features[ENTITY_FEATURES.NUMBER_OF_MANAGERS];
            const isLimitReached = numManagersLimit > 0 ? !(managers.length < numManagersLimit) : false;
            setCanSetAsManager(!isLimitReached);
        } else {
            setCanSetAsManager(false);
        }
    };

    return (
        <Formik
            initialValues={mapToFormModel(userDetails)}
            onSubmit={handleSubmit}
            validate={validate}
            enableReinitialize={false}
        >
            {({ values, touched, errors, isValid, dirty, setFieldValue, setFieldError, setValues, setTouched, setSubmitting, isSubmitting }) => {

                setIsDirty(dirty);
                setIsValid(isValid);
                setEntityRestrictionsById(values.entityId);

                return (
                    <Form className="text-left clearfix">
                        <EditUserForm
                            values={values}
                            entities={entities}
                            touched={touched}
                            errors={errors}
                            requestErrors={requestErrors}
                            resetRequestErrors={resetUserRequestErrors}
                            setFieldValue={setFieldValue}
                            setFieldError={setFieldError}
                            setValues={setValues}
                            setTouched={setTouched}
                            emailReadOnly={true}
                            hasSpecialPermissions={hasSpecialPermissions}
                            isCreateMode={false}
                            resetSubmitting={() => setSubmitting(false)}
                            canSetAsManager={canSetAsManager}
                        />

                        <div className="btn-toolbar pull-right">
                            <button type="submit" disabled={isSubmitDisabled()} className="btn btn-success"><Save size={16} />{t('edit_profile_button_save')}</button>
                            <button type="button" className="btn btn-outline" onClick={handleCancel}>{t('edit_profile_button_cancel')}</button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

UserDetailsEdit.propTypes = {
    userDetails: PropTypes.shape({ id: PropTypes.number }),
    entities: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    usersList: PropTypes.array,

    hasSpecialPermissions: PropTypes.bool,
    errors: PropTypes.object,
    requestErrors: PropTypes.object,
    resetUserRequestErrors: PropTypes.func.isRequired,

    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired
};

export default UserDetailsEdit;