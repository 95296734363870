import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';

import AutoComplete from '../../common/form/autocomplete';
import BSFormControl from '../../common/form/bsFormControl';
import Toggle from '../../common/form/toggle';
import { Field, ErrorMessage } from 'formik';
import formValidators from '../../../helpers/formValidators';
import { CONFIRM_MODAL } from '../../../helpers/modalTypes';
import FeaturesContext from "../../../scenes/contexts/featuresContext";
import { ENTITY_FEATURES } from "hollerlive-shared/constants";
import { COMPARATORS } from "../../../helpers/featuresHelper";
import NoPermissionLabel from '../../common/noPermissionLabel';
import messages from '../../../helpers/messages';
import i18n from '../../../i18n';

class AnswerItem extends Component {
	constructor(props) {
		super(props);

		const disableJumpQuestions = !!props.disableJumpQuestions;
		const { answer } = props;

		this.state = {
			disableJumpQuestions,
			jumpToQuestionEnabled: answer ? answer.jumpToQuestionEnabled : false,
			nextQuestionId: answer ? answer.nextQuestionId : null,
			isAnswerLink: false,
			t: i18n.t.bind(i18n)
		};
		
		if (answer && answer.isLink) {
			this.state.isAnswerLink = true;
		}

		this.deleteAnswer = this.deleteAnswer.bind(this);
		this.handleConfirmRemoveAnswer = this.handleConfirmRemoveAnswer.bind(this);
		this.onConfirmRemoveAnswer = this.onConfirmRemoveAnswer.bind(this);
		this.onJumpToQuestionToggleChange = this.onJumpToQuestionToggleChange.bind(this);
		this.onJumpToQuestionSelected = this.onJumpToQuestionSelected.bind(this);
	}
	
	static getDerivedStateFromProps(nextProps, prevState) {
		const disableJumpQuestions = !!nextProps.disableJumpQuestions;
		const jumpToQuestionEnabled = !!nextProps.answer.jumpToQuestionEnabled;
		const newState = {
			nextQuestionId: nextProps.answer.nextQuestionId
		};
		
		if ( jumpToQuestionEnabled !== prevState.jumpToQuestionEnabled ) {
			newState.jumpToQuestionEnabled = jumpToQuestionEnabled;
		}

		if (disableJumpQuestions !== prevState.disableJumpQuestions) {
			newState.disableJumpQuestions = disableJumpQuestions;
		}

		return newState;
	}

	onValuesChange(changes = []) {
		const changesForUpdate = {};
		changes.forEach(c => changesForUpdate[c.field] = c.value);

		const updatedAnswer = Object.assign({}, this.props.answer, changesForUpdate);
		this.props.onAnswerValuesChange(updatedAnswer);
	}

	onJumpToQuestionSelected(jumpQuestionId) {
		this.onValuesChange([
			{ field: 'jumpToQuestionEnabled', value: this.state.jumpToQuestionEnabled },
			{ field: 'nextQuestionId', value: jumpQuestionId }
		]);
	}
	
	onJumpToQuestionToggleChange() {
		const toggledJumpToQuestion = !this.state.jumpToQuestionEnabled;
		
		if (toggledJumpToQuestion === false) {
			this.onValuesChange([
				{ field: 'jumpToQuestionEnabled', value: toggledJumpToQuestion },
				{ field: 'nextQuestionId', value: null }]);
		} else {
			this.onValuesChange([{ field: 'jumpToQuestionEnabled', value: toggledJumpToQuestion }]);
		}
		
		this.setState({ jumpToQuestionEnabled: toggledJumpToQuestion });
	}

	deleteAnswer() {
		const answerText = this.props.answer.text;
		if (answerText.length === 0) {
			this.props.onRemoveAnswer(this.props.answerIndex);
		} else {
			this.handleConfirmRemoveAnswer(answerText);
		}
	}

	handleConfirmRemoveAnswer(answerText) {
		const t = this.state.t;
		const modalConfig = {
			title: t('modal_qna_answer_delete_title'),
			message: t('modal_qna_answer_delete_message', { answerText }),
			onClose: () => this.context.hideModal(),
			onConfirm: this.onConfirmRemoveAnswer,
		};

		this.context.showModal(CONFIRM_MODAL, modalConfig);
	}

	onConfirmRemoveAnswer() {
		this.context.hideModal();
		this.props.onRemoveAnswer(this.props.answerIndex);
	}
	
	setIsAnswerLink(value) {
		const { fieldPath } = this.props;
		
		this.setState({ isAnswerLink: value }, () => {
			this.context.setFieldValue(`${ fieldPath }.isLink`, value);
			if (this.state.isAnswerLink === false) {
				this.context.setFieldValue(`${ fieldPath }.link`, '');
			}
		});
	}
	
	_displayFormControlByType(answerType) {
		const { fieldPath, errors, answer } = this.props;
		
		const t = this.state.t;
		
		switch (answerType) {
			case "close": {
				return (
					<div>
						<BSFormControl required hasErrors={ formValidators.fieldHasErrors(errors, `${ fieldPath }.text`) } className={ ' uitest-closed-answer' }>
							<Field type="text" name={ `${ fieldPath }.text` } className={ `form-control` }/>
							<ErrorMessage name={ `${ fieldPath }.text` } component="div" className='control-label'/>
						</BSFormControl>
						
						<BSFormControl>
							<Field name={ `${ fieldPath }.isLink` } value={ this.state.isAnswerLink }>
								{ ({ field }) =>
									<Toggle value={field.value} onChange={(value) => this.setIsAnswerLink(value)}/>
								}
							</Field>
							<label>{t('topic_builder_qna_answer_is_link')}</label>
						</BSFormControl>
						{ this.state.isAnswerLink ? (
							<BSFormControl required hasErrors={ formValidators.fieldHasErrors(errors, `${ fieldPath }.link`) }>
								<Field type="text" name={ `${ fieldPath }.link` } className={ `form-control` } placeholder={ 'https://example.com' }/>
								<ErrorMessage name={ `${ fieldPath }.link` } component="div" className='control-label'/>
							</BSFormControl>
						) : null }
					</div>
				);
			}
			case "dropdown":
				return (
					<div>
						<BSFormControl
							label={t('topic_builder_qna_dropdown_answer_label')}
							subLabel={t('topic_builder_qna_dropdown_answer_sublabel')}
							className="input-open-answer uitest-open-answer"
						>
							<Field component="textarea" name={`${fieldPath}.text`} className={`form-control`} />
						</BSFormControl>
					</div>
				);
			case "location_request":
				return (
					<div>
						<BSFormControl
							label={t('topic_builder_qna_location_request_button_label')}
							subLabel={t('topic_builder_qna_location_request_button_sublabel')}
							className="input-open-answer uitest-open-answer"
						>
							<Field type="text" name={ `${ fieldPath }.text` } className={ `form-control` }/>
						</BSFormControl>
					</div>
				);
			case "open":
			default:
				{
					return <div>
						<BSFormControl
							label={t('topic_builder_qna_openended_label')}
							subLabel={t('topic_builder_qna_openended_sublabel')}
							className="input-open-answer uitest-open-answer"
						>
							<Field component="textarea" name={`${fieldPath}.placeholderText`} className={`form-control`} />
						</BSFormControl>
						
						<FeaturesContext 
							requiredFeatures={[{name: ENTITY_FEATURES.ATTACH_REPLY_IMAGE, compare: COMPARATORS.IS_TRUE}]}
							renderOtherwise={
								<BSFormControl>
									<NoPermissionLabel position="left" message={messages.permissions.features.noAnswerImage} />
									<Toggle value={false} disabled={true} />
									<label>{t('topic_builder_qna_openended_allow_upload')}</label>
								</BSFormControl>
							}
						>
							<BSFormControl>
								<Field name={`${fieldPath}.canAttachImage`} value={answer.canAttachImage} className="uitest-attach-image">
									{({ field }) =>
										<Toggle value={field.value} onChange={(value) => this.onValuesChange([{ field: 'canAttachImage', value }])} />
									}
								</Field>
								<label>{t('topic_builder_qna_openended_allow_upload')}</label>
							</BSFormControl>
						</FeaturesContext>
					</div>;
				}
		}
	}

	render() {
		const {
			jumpQuestions,
			answerType,
			answerIndex,
			canDelete,
			fieldPath,
			errors
		} = this.props;

		const t = this.state.t;

		return (
			<div className="answers ordered-form-fields form-group">
				<div className="ordered-form-fields-item with-action">
					{answerType === 'close' ? <span className="number">{answerIndex + 1}</span> : null}

					<div className="form-control-container">
						{this._displayFormControlByType(answerType)}
						
						<FeaturesContext 
							requiredFeatures={[{name: ENTITY_FEATURES.JUMP_QUESTIONS, compare: COMPARATORS.IS_TRUE}]}
							renderOtherwise={
								<BSFormControl>
                                    <div className="col-md-3">
										<NoPermissionLabel position="left" message={messages.permissions.features.noJumpQuestions} />
										<Toggle value={false} disabled={true} />
										<label>{t('topic_builder_qna_jump_to_question')}</label>
									</div>
								</BSFormControl>
							}
						>
							<BSFormControl className="clearfix row-fluid" required hasErrors={formValidators.fieldHasErrors(errors, `${fieldPath}.nextQuestionId`)}>
								<div className="col-md-3">
									<Toggle
                                        value={this.state.jumpToQuestionEnabled}
                                        onChange={this.onJumpToQuestionToggleChange}
										disabled={jumpQuestions.length < 1 || this.state.disableJumpQuestions}
                                        className='uitest-jump-question-toggle'
									/>
									<label>{t('topic_builder_qna_jump_to_question')}</label>
								</div>
								
								<div className="col-md-9">
									{this.state.jumpToQuestionEnabled &&
									<Field name={`${fieldPath}.nextQuestionId`}
										   className='uitest-jump-question-options'>
										{(answerValueObject) => (
											<div>
												<AutoComplete
													className={`form-control`}
													labelField="question"
													valueField="questionId"
													value={answerValueObject.field.value || ""}
													data={jumpQuestions || []}
													onChange={this.onJumpToQuestionSelected}
												/>
												
												<ErrorMessage name={`${fieldPath}.nextQuestionId`} component="span"
															  className='control-label'/>
											</div>
										)}
									</Field>
									}
								</div>
							</BSFormControl>
						</FeaturesContext>
					</div>

					{canDelete !== false &&
						<span className="action"><Icons.XCircle className="text-danger uitest-btn-delete" onClick={this.deleteAnswer} /></span>
					}
				</div>
			</div>
		);
	}
}

AnswerItem.contextTypes = {
	showModal: PropTypes.func,
	hideModal: PropTypes.func,
	setFieldValue: PropTypes.func
};

AnswerItem.propTypes = {
	/** @prop {array} jumpQuestions the current set of questions from wich to choose a jump to */
	jumpQuestions: PropTypes.array.isRequired,

	onRemoveAnswer: PropTypes.func,

	answerIndex: PropTypes.number.isRequired,

	/** @prop {object} answer the anwer form values object */
	answer: PropTypes.shape({
		answerId: PropTypes.number.isRequired,
		text: PropTypes.string,
		link: PropTypes.string,
		isLink: PropTypes.bool,
		canAttachImage: PropTypes.bool,
		placeholderText: PropTypes.string,
		nextQuestionId: PropTypes.number,
		jumpToQuestionEnabled: PropTypes.bool.isRequired
	}),

	errors: PropTypes.object,

	fieldPath: PropTypes.string,

	canDelete: PropTypes.bool,

	/** @prop {string} answerType the type of answer, open - for fill-in-the box or close - for predefined answers */
	answerType: PropTypes.string.isRequired,

	/** @prop {bool} disableJumpQuestions if the jump to question should be disabled by outer reason */
	disableJumpQuestions: PropTypes.bool,

	onAnswerValuesChange: PropTypes.func
};

export default AnswerItem;
