import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as types from './actionTypes';
import { graphPost, streamGet } from '../api/expressApi';
import moment from 'moment';
import { handleCommonError } from './errorActions';
import { LATEST_VOTES_MAX_COUNT } from '../helpers/voteDataHelper';
import { createTopicHash } from 'hollerlive-shared/utils';

function buildTopicsTreeRequest() {
    return { type: types.BUILD_TOPIC_TREE_REQUEST };
}
function buildTopicsTreeSuccess(topicsWithLevels) {
    return { type: types.BUILD_TOPIC_TREE_SUCCESS, payload: topicsWithLevels };
}
function loadTopicsTreeRequest() {
    return { type: types.LOAD_TOPIC_TREE_REQUEST };
}
function loadTopicsTreeSuccess(topics, votesStats) {
    return { type: types.LOAD_TOPIC_TREE_SUCCESS, payload: { topics, votesStats } };
}
export function resetTopicTree() {
    return { type: types.RESET_TOPIC_TREE };
}

/** load topictree structure from stream api */
export function buildTopicsTree(parentTopic) {
    return async (dispatch) => {
        try {
            dispatch(showLoading());
            dispatch(buildTopicsTreeRequest());
            const parentTopicHash = createTopicHash(parentTopic.name);
            const childrenWithLevels = await streamGet('/topictree/findchildren?topichash=' + parentTopicHash);
            const topicsWithLevels = Object.assign(
                {},
                { 
                    id: parentTopic.id,
                    name: parentTopic.name,
                    dashName: parentTopic.dashName
                },
                childrenWithLevels
            );
            dispatch(buildTopicsTreeSuccess(topicsWithLevels));
            dispatch(hideLoading());
        } catch (error) {
            handleCommonError(dispatch, error);
        }
    };
}

/** loads votes' stats for the loaded topics from graph api */
export function loadTopicsTreeStats(dateFrom, dateTo) {
    return async (dispatch, getState) => {
		try {
            dispatch(showLoading());
            dispatch(loadTopicsTreeRequest());
			const dateFromUtc = moment(dateFrom).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
			const dateToUtc = moment(dateTo).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
			const topics = getState().topics;
			if (topics.length > 0) {
                const topicIds = topics.map(topic => topic.id);
                const topicsStatsRequest = {
                    topics: topicIds,
                    startDate: dateFromUtc,
                    endDate: dateToUtc,
                    limit: LATEST_VOTES_MAX_COUNT
                };
                const topicsVotesStats = await graphPost('/topicVotes/statsForSelected', topicsStatsRequest);
                dispatch(loadTopicsTreeSuccess(topics, topicsVotesStats));
            }
			dispatch(hideLoading());
		} catch (error) {
			handleCommonError(dispatch, error);
		}
	};
}

// temporary solution, eventually the dates must be sent back with the topitree response from the server
export function setTopicsTreeFilterPeriod(dateFrom, dateTo, intervalIndex) {
    return dispatch => {
        const periodFilter = {
            dateFrom,
            dateTo,
            intervalIndex
        };
        dispatch({ type: types.SET_TOPIC_TREE_PERIODFILTER, payload: periodFilter });
    };
}

