import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import * as Icons from 'react-feather';

import './css/basicTopicDetails.css';

import MediaDnD from '../common/form/MediaDnD';
import { Toggle } from '../common/form/toggle';
import BSFormControl from '../common/form/bsFormControl';
import ServerErrorMessage from '../common/serverErrorMessage';

import Languages from '../../helpers/languages';
import { getTopicPreviewUrl, getValueByFieldPath } from '../../helpers/utils';
import FeaturesContext from '../../scenes/contexts/featuresContext';
import { ENTITY_FEATURES } from 'hollerlive-shared/constants';
import { COMPARATORS } from '../../helpers/featuresHelper';
import NoPermissionLabel from '../common/noPermissionLabel';
import messages from '../../helpers/messages';
import PermissionsContext from '../../scenes/contexts/permissionsContext';
import { PERMISSIONS } from '../../helpers/permissionsHelper';
import DebouncedInputField from '../common/form/debouncedInputField';
import i18n from '../../i18n';
import { processBackgroundImage, processBackgroundPortraitImage } from '../../helpers/imageUtils';

export class BasicTopicDetails extends Component {
    constructor(props) {
        super(props);
        const { basicSettings } = props.values;

        let imgPreview = "";
        let urlPreview = "";

        if (basicSettings) {
            imgPreview = basicSettings.processedImage ? basicSettings.processedImage : basicSettings.imageUrl;
            urlPreview = basicSettings.title ? getTopicPreviewUrl(basicSettings.title) : "";
        }

        this.state = {
            displayNameWarning: '',
            urlPreview,
            imgPreview,
            t: i18n.t.bind(i18n)
        };

        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.handleFieldTouchedChange = this.handleFieldValueChange.bind(this);
        this.handlePrivateSet = this.handlePrivateSet.bind(this);
        this.validateDuplicateTopicName = this.validateDuplicateTopicName.bind(this);
    }

    static getDerivedStateFromProps(props) {
        const { title } = props.values.basicSettings;

        if (title && title.length > 0) {
            return {
                urlPreview: getTopicPreviewUrl(title)
            };
        }

        return null;
    }

    getLangByCode(code) {
        return Languages.find(l => l.code === code);
    }

    isTopicDisplayNameTooLong(value) {
        return value ? value.length > 80 : false;
    }

    /*handleImageUpload(imageObj, setFieldValue) {
        applyImageCropAndWatermark(imageObj).then(processedImg => {
            setFieldValue('basicSettings.processedImage', processedImg);
            this.setState({ imgPreview: processedImg });
        }).catch(err => {
            logger.error(err);
        });
    }*/

    getLanguagesOptions() {
        const t = this.state.t;
        return Languages.map(l => {
            return (
                <option key={l.code} value={l.code}>
                    {t(l.name)}
                </option>
            );
        });
    }

    //onImageError(setFieldError, reason) {
    //    setFieldError('basicSettings.processedImage', reason);
    //}

    fieldHasErrors(errors = {}, field) {
        const fieldError = getValueByFieldPath(errors, field);
        return fieldError && fieldError.length > 0;
    }

    handleFieldValueChange(field, value) {
        this.props.setFieldValue(field, value);
    }

    validateDuplicateTopicName(topicName) {
        this.context.validateTopicNameDuplicate(topicName);
    }

    handlePrivateSet(value) {
        if (value) {
            this.props.setFieldValue('postVoteSettings.showCopyLink', false);
            this.props.setFieldValue('postVoteSettings.showFbShare', false);
        } else {
            this.props.setFieldValue('postVoteSettings.showCopyLink', this.props.model.postVoteSettings.showCopyLink);
            this.props.setFieldValue('postVoteSettings.showFbShare', this.props.model.postVoteSettings.showFbShare);
        }
        this.props.setFieldValue('basicSettings.isPrivate', value);
        this.props.setFieldTouched('basicSettings.isPrivate');
    }

    render() {
        const {
            editMode,
            backendErrors,
            errors,
            values,
            setFieldValue,
            setFieldError,
            handleBlur,
            isDirty
        } = this.props;

        const t = this.state.t;

        return (
            <div className='basic-topic-details'>
                <div className="row-fluid clearfix uitest-topic-visibility">
                    <div className="col-md-12 row-fluid">
                        <div className="col-md-6">
                            <FeaturesContext
                                requiredFeatures={[{
                                    name: ENTITY_FEATURES.HAS_PRIVATE_TOPICS,
                                    compare: COMPARATORS.IS_TRUE
                                }]}
                                renderOtherwise={
                                    <BSFormControl
                                        className='row-fluid private-field-containter' inline={true}
                                        hasErrors={this.fieldHasErrors(errors, 'basicSettings.isPrivate')}
                                    >
                                        <Field name='basicSettings.isPrivate'>
                                            {({ field }) =>
                                                <div>
                                                    <NoPermissionLabel
                                                        position='left'
                                                        message={messages.permissions.features.noPrivateTopics}
                                                    />
                                                    <Toggle value={field.value} disabled={true} />
                                                    <label>{t('topic_builder_private_topic_label')}</label>
                                                    <label className='sub-label'>
                                                        {t('topic_builder_private_topic_sublabel')}
                                                    </label>
                                                </div>
                                            }
                                        </Field>
                                    </BSFormControl>
                                }
                            >
                                <BSFormControl
                                    className='row-fluid private-field-containter' inline={true}
                                    hasErrors={this.fieldHasErrors(errors, 'basicSettings.isPrivate')}
                                >
                                    <Field name='basicSettings.isPrivate'>
                                        {({ field }) =>
                                            <div>
                                                <Toggle value={field.value} onChange={(value) => this.handlePrivateSet(value)} />
                                                <label>{t('topic_builder_private_topic_label')}</label>
                                                <label className='sub-label'>{t('topic_builder_private_topic_sublabel')}</label>
                                            </div>
                                        }
                                    </Field>
                                    <ErrorMessage name="basicSettings.isPrivate" component="span" className='control-label' />
                                </BSFormControl>
                            </FeaturesContext>
                        </div>

                        <div className="col-md-6">
                            <PermissionsContext requiredPermissions={[PERMISSIONS.ADMIN]}
                                renderOtherwise={
                                    <BSFormControl
                                        className='row-fluid disabled-field-containter' inline={true}
                                        hasErrors={this.fieldHasErrors(errors, 'basicSettings.isDisabled')}
                                    >
                                        <Field name='basicSettings.isDisabled'>
                                            {({ field }) =>
                                                <div>
                                                    <Toggle value={field.value} disabled={true} />
                                                    <label>{t('topic_builder_disable_topic_label')}</label>
                                                    <label className='sub-label'>{t('topic_builder_disable_topic_sublabel')}</label>
                                                </div>
                                            }
                                        </Field>
                                        <ErrorMessage name="basicSettings.isDisabled" component="span" className='control-label' />
                                    </BSFormControl>
                                }>
                                <BSFormControl
                                    className='row-fluid disabled-field-containter' inline={true}
                                    hasErrors={this.fieldHasErrors(errors, 'basicSettings.isDisabled')}
                                >
                                    <Field name='basicSettings.isDisabled'>
                                        {({ field }) =>
                                            <div>
                                                <Toggle value={field.value} onChange={(value) => this.handleFieldValueChange(field.name, value)} />
                                                <label>{t('topic_builder_disable_topic_label')}</label>
                                                <label className='sub-label'>{t('topic_builder_disable_topic_sublabel')}</label>
                                            </div>
                                        }
                                    </Field>
                                    <ErrorMessage name="basicSettings.isDisabled" component="span" className='control-label' />
                                </BSFormControl>
                            </PermissionsContext>
                        </div>
                    </div>
                </div>

                <div className="row-fluid clearfix uitest-topic-default-lang">
                    <div className="col-md-12">
                        <BSFormControl label={t('topic_builder_default_language_label')}>
                            <div className="col-md-4">
                                <Field disabled={editMode} component="select" className="form-control" name="basicSettings.defaultLang">
                                    {this.getLanguagesOptions()}
                                </Field>
                                <ErrorMessage name="basicSettings.defaultLang" component="span" className='control-label' />
                                {isDirty === false &&
                                    <ServerErrorMessage errors={backendErrors} name="basicSettings.defaultLang" className="control-label" />
                                }
                            </div>
                            <div className="col-md-6">
                                {!editMode && (
                                    <p className="message bg-warning">
                                        <Icons.AlertTriangle size={14} /> {t('topic_builder_default_language_sublabel')}
                                    </p>
                                )}
                            </div>
                        </BSFormControl>
                    </div>
                </div>

                <div className="row-fluid uitest-topic-name single-line-inputs clearfix">
                    <BSFormControl
                        label={t('topic_builder_topic_name_label')}
                        subLabel={t('topic_builder_topic_name_sublabel')}
                        hasErrors={this.fieldHasErrors(errors, 'basicSettings.title')}
                        required
                    >
                        {editMode ? (
                            <Field type="text" name="basicSettings.title" className="form-control" disabled={true} />
                        ) :
                            <Field name="basicSettings.title">
                                {({ field }) => (
                                    <DebouncedInputField
                                        onDebouncedAction={this.validateDuplicateTopicName}
                                        value={field.value}
                                        debounceOnChangeTimeout={1500}
                                        placeholder={t('topic_builder_topic_name_placeholder')}
                                        onValueChange={(topicName) => this.handleFieldValueChange(field.name, topicName)}
                                        onBlur={handleBlur}
                                        onFocus={handleBlur}
                                        name={field.name}
                                    />
                                )}
                            </Field>
                        }
                        <ErrorMessage name="basicSettings.title" component="span" className='control-label' />
                        <ServerErrorMessage errors={backendErrors} name="basicSettings.title" className="control-label" />

                        {!editMode && (
                            <p className="message bg-warning">
                                <Icons.AlertTriangle size={14} /> {t('topic_builder_topic_name_warning')}
                            </p>
                        )}
                    </BSFormControl>

                    <BSFormControl label={t('topic_builder_url_name_label')} subLabel={t('topic_builder_url_name_sublabel')}>
                        <input type="text" name="urlPreview" className="form-control" placeholder={t('topic_builder_url_name_placeholder')} value={this.state.urlPreview} disabled />
                    </BSFormControl>
                </div>

                <div className="row-fluid clearfix">
                    <BSFormControl label={t('topic_builder_display_name_label')} subLabel={t('topic_builder_display_name_sublabel')} hasErrors={this.fieldHasErrors(errors, 'basicSettings.displayName')}>
                        <Field type="text" name="basicSettings.displayName" className="form-control" placeholder={values.basicSettings && values.basicSettings.title} />
                        <ErrorMessage name="basicSettings.displayName" component="span" className='control-label' />
                        {this.isTopicDisplayNameTooLong(values.basicSettings.displayName) && (
                            <p className="message bg-warning">
                                <Icons.AlertTriangle size={14} /> {t('topic_builder_display_name_warning')}
                            </p>
                        )}
                    </BSFormControl>
                </div>

                <div className="row-fluid clearfix">
                    <BSFormControl label={t('topic_builder_dash_name_label')} hasErrors={this.fieldHasErrors(errors, 'basicSettings.dashName')}>
                        <Field type="text" name="basicSettings.dashName" className="form-control" placeholder={values.basicSettings && values.basicSettings.title} />
                        <ErrorMessage name="basicSettings.dashName" component="span" className='control-label' />
                    </BSFormControl>
                </div>

                <BSFormControl label={t('topic_builder_topic_description_label')} className="clearfix row-fluid">
                    <Field component="textarea" name="basicSettings.description" className="form-control" />
                </BSFormControl>

                <BSFormControl
                    label={t('topic_builder_external_description_label')}
                    subLabel={t('topic_builder_external_description_sublabel')}
                >
                    <Field type="text" name="basicSettings.extDescriptionUrl" className="form-control" />
                </BSFormControl>

                <div className="form-group">
                    <Field name="basicSettings.voterShouldConcent">
                        {({ field }) =>
                            <Toggle value={field.value} onChange={(value) => this.handleFieldValueChange(field.name, value)} />
                        }
                    </Field>
                    <span className="checkbox-label">{t('topic_builder_should_give_consent_label')}</span>
                </div>

                <h4>Хоризонтален фон</h4>
                {!this.props.values.basicSettings.id ?
                    <p style={{ margin: '10px 0 30px 10px' }}>
                        Фон може да се прикачва само на вече публикувана тема.
                    </p> :
                    <MediaDnD
                        model={this.props.values}
                        previewField={'basicSettings.backgroundUrl'}
                        legacyField={'basicSettings.imageUrl'}
                        /*mediaUrl={(() => {
                            const basicSettings = this.props.values.basicSettings;
                            if(basicSettings.backgroundUrl)
                                return basicSettings.backgroundUrl;
                            else if(basicSettings.imageUrl)
                                return basicSettings.imageUrl;
                            return null;
                        })()}*/
                        uploadImageEndpoint={`/topics/${this.props.values.basicSettings.id}/upload_images/background`}
                        imageProcessor={processBackgroundImage}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        fieldNames={[
                            'basicSettings.backgroundUrl',
                            'basicSettings.backgroundThumbnailUrl'
                        ]}
                    />
                }

                <h4>Вертикален фон</h4>
                {!this.props.values.basicSettings.id ?
                    <p style={{ margin: '10px 0 30px 10px' }}>
                        Фон може да се прикачва само на вече публикувана тема.
                    </p> :
                    <MediaDnD
                        model={this.props.values}
                        previewField={'basicSettings.backgroundPortraitUrl'}
                        uploadImageEndpoint={`/topics/${this.props.values.basicSettings.id}/upload_images/background`}
                        imageProcessor={processBackgroundPortraitImage}
                        setFieldValue={setFieldValue}
                        setFieldError={setFieldError}
                        fieldNames={['basicSettings.backgroundPortraitUrl']}
                    />
                }
            </div>
        );
    }
}

BasicTopicDetails.propTypes = {
    values: PropTypes.shape({
        basicSettings: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            displayName: PropTypes.string,
            defaultLang: PropTypes.string,
            description: PropTypes.string,
            extDescriptionUrl: PropTypes.string,
            imageUrl: PropTypes.string,
            backgroundUrl: PropTypes.string,
            backgroundThumbnailUrl: PropTypes.string,
            backgroundPortraitUrl: PropTypes.string,
            voterShouldConcent: PropTypes.bool,
            isPrivate: PropTypes.bool
        })
    }).isRequired,
    model: PropTypes.shape(),
    backendErrors: PropTypes.shape({
        basicSettings: PropTypes.object
    }),
    errors: PropTypes.object,
    editMode: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    isDirty: PropTypes.bool,
};

BasicTopicDetails.contextTypes = {
    validateTopicNameDuplicate: PropTypes.func
};

export default BasicTopicDetails;
